.about {
    background: linear-gradient(116.37deg, #18181f 13.15%, #22212a 81.16%);
    padding: 0 20px 0 0
}

.about__wrapper {
    max-width: 1525px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 auto
}

.about__img__wrap {
    height: 900px;
    width: 500px;
    overflow: hidden;
    position: relative
}

.about__img__wrap .top-shadow {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 200px;
    background-image: linear-gradient(#1a1a22 30%, rgb(28 27 35/79%) 80%, transparent);
    z-index: 5
}

.about__img__wrap .bottom-shadow {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 200px;
    background-image: linear-gradient(0deg, #1e1d26 30%, rgb(30 29 37/83%) 80%, transparent);
    z-index: 5
}

.about__slider__left {
    position: absolute;
    width: calc(50% - 10px);
    z-index: 1;
    top: 0;
    left: 0;
    -webkit-animation: slideTop 20s linear infinite;
    animation: slideTop 20s linear infinite
}

.about__slider__left img {
    width: 240px;
    height: 240px;
    margin-bottom: 20px
}

.about__slider__right {
    position: absolute;
    width: calc(50% - 10px);
    z-index: 1;
    bottom: 0;
    right: 0;
    -webkit-animation: downToBottom 20s linear infinite;
    animation: downToBottom 20s linear infinite
}

.about__slider__right img {
    width: 240px;
    height: 240px;
    margin-bottom: 20px
}

.about__content__wrap {
    width: 50%;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    margin-left: 80px;
    padding: 50px 0
}

.about__content__wrap .title {
    font-size: 32px;
    margin-bottom: 20px;
    line-height: 35px;
}

.about__content__wrap p {
    font-size: 16px;
    color: #fff;
    line-height: 1.7
}

.slider .about-content p {
    font-size: 16px;
    color: #fff;
    line-height: 1.7
}

.our__story .about-content p {
    font-size: 16px;
    color: #fff;
    line-height: 1.7
}

@-webkit-keyframes slideTop {
    0% {
        top: 0
    }

    to {
        top: -1340px
    }
}

@keyframes slideTop {
    0% {
        top: 0
    }

    to {
        top: -1340px
    }
}

@-webkit-keyframes downToBottom {
    0% {
        bottom: 0
    }

    to {
        bottom: -1340px
    }
}

@keyframes downToBottom {
    0% {
        bottom: 0
    }

    to {
        bottom: -1340px
    }
}

@media only screen and (max-width:768px) {
    .about__img__wrap {
        margin: 0 auto;
    }
}


@media only screen and (max-width:414px) {
    .about__content__wrap {
        margin-left: 40px;
    }
    .about__img__wrap {
        height: 400px;
    }
    .about__img__wrap .top-shadow,
    .about__img__wrap .bottom-shadow {
        height: 50px;
    }
    .about__content__wrap .title {
        font-size: 24px;
    }
    .play_content ul li {
        font-size: 14px;
        font-weight: 300;
        text-align: initial;
    }
    .section-title .title {
        font-size: 18px;
        line-height: 24px;
    }
    .c_rate {
        font-size: 14px;
        font-weight: 700;
    }
    .unique-section-btn ul {
        display: grid;
    }
    .tab_video h3 {
        font-size: 20px;
    }
    .about-video {
        height: auto;
    }

}
